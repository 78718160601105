import api from "../utils/api";

export async function getDetailPorto({ portoID }) {
  const res = await api.get(`/api/v1/portfolios/${portoID}`);

  return res?.data?.data;
}

export async function getListPorto() {
  const res = await api.get(`/api/v1/portfolios`);

  return res?.data?.data;
}
