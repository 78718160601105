import React from "react";
import ContactForm from "./component/ContactForm";
import OfficeLocation from "./component/OfficeLocation";
import { useTranslation } from "react-i18next";

export default function Index(props) {
  const { t } = useTranslation();
  return (
    <div>
      <section className="breadcrumb-area">
        <div className="text-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 v-center">
                <div className="bread-inner">
                  <div className="bread-menu wow fadeInUp" data-wow-delay=".2s">
                    <ul>
                      <li>
                        <a href="/">{t("Home")}</a>
                      </li>
                      <li>
                        <a href="#/">{t("Contact")}</a>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="bread-title wow fadeInUp"
                    data-wow-delay=".5s"
                  >
                    <h2>{t("ContactUsContent.Title")}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContactForm />
      <OfficeLocation />
    </div>
  );
}
