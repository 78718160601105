import React from "react";
import { useTranslation } from "react-i18next";

export default function ShortAbout(props) {
  const { t } = useTranslation();
  return (
    <section className="about-sec-rpb pad-tb">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-lg-10">
            <div className="common-heading">
              <span>{t("HomeContent.WeAreSolution")}</span>
              <h1 className="mb30">
                <span className="text-second">{t("HomeContent.Friends")}</span>{" "}
                {t("HomeContent.ForBuild")}
              </h1>
              <p>
                {t("HomeContent.WeProvide")}{" "}
                <span className="text-bold">
                  {t("HomeContent.WeProvide2")}{" "}
                </span>{" "}
                {t("HomeContent.WeProvide3")}{" "}
                <span className="text-bold">{t("HomeContent.WeProvide4")}</span>{" "}
                {t("HomeContent.WeProvide5")}
                <span className="text-bold">
                  {" "}
                  {t("HomeContent.WeProvide6")}
                </span>{" "}
                {t("HomeContent.WeProvide7")}
                <span className="text-bold">
                  {" "}
                  {t("HomeContent.WeProvide8")}
                </span>{" "}
                {t("HomeContent.WeProvide9")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
