import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import AboutProject from "./component/AboutProject";
import Feature from "./component/Feature";
import Challenges from "./component/Challenges";
import Solutions from "./component/Solutions";
import { getDetailPorto } from "../../../services/PortoServices";

export default function Index(props) {
  const { t } = useTranslation();
  const { id } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getDetailPorto({ portoID: id });
        setData(result);
      } catch (error) {
        // Handle error (e.g., set error state)
      }
    };

    fetchData();
  }, [id]);
  return (
    <div>
      {/* <!--Breadcrumb Area--> */}
      <section className="breadcrumb-area banner-1">
        <div className="text-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 v-center">
                <div className="bread-inner">
                  <div className="bread-menu">
                    <ul>
                      <li>
                        <a href="/">{t("Home")}</a>
                      </li>
                      <li>
                        <a href="#/">{t("Portofolio.Detail.Title")}</a>
                      </li>
                    </ul>
                  </div>
                  <div className="bread-title">
                    <h2>{data?.name}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--End Breadcrumb Area--> */}
      <AboutProject portoDetail={data} />
      <Feature portoDetail={data} />
      <Solutions portoDetail={data} />
      <Challenges portoDetail={data} />
    </div>
  );
}
