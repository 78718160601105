import React from "react";
import { useTranslation } from "react-i18next";

export default function TeamLeader(props) {
  const { t } = useTranslation();
  return (
    <section className="team bg-gradient99 pad-tb">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="common-heading ptag">
              <span>{t("OurTeamContent.SectionOne.Title")}</span>
              <h2>{t("OurTeamContent.SectionOne.Description")}</h2>
              <p className="mb0"></p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-3 col-sm-6">
            <div className="full-image-card hover-scale">
              <div className="image-div">
                <a href="/#">
                  <img
                    src="assets/images/team/team-1a.jpeg"
                    alt="team"
                    className="img-fluid"
                  />
                </a>
              </div>
              <div className="info-text-block">
                <h4>
                  <a href="/#">Ubaidillah Hakim</a>
                </h4>
                <p>{t("OurTeamContent.SectionOne.List.0.Title")}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="full-image-card hover-scale">
              <div className="image-div">
                <a href="/#">
                  <img
                    src="assets/images/team/team-2a.jpeg"
                    alt="team"
                    className="img-fluid"
                  />
                </a>
              </div>
              <div className="info-text-block">
                <h4>
                  <a href="/#">Nugroho Samiyono</a>
                </h4>
                <p>{t("OurTeamContent.SectionOne.List.1.Title")}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="full-image-card hover-scale">
              <div className="image-div">
                <a href="/#">
                  <img
                    src="assets/images/team/team-4a.jpeg"
                    alt="team"
                    className="img-fluid"
                  />
                </a>
              </div>
              <div className="info-text-block">
                <h4>
                  <a href="/#">Hiegar Ramadani</a>
                </h4>
                <p>{t("OurTeamContent.SectionOne.List.2.Title")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
