import React from "react";
import { useTranslation } from "react-i18next";
import Marquee from "react-fast-marquee";

export default function Clients(props) {
  const { t } = useTranslation();
  return (
    <section className="clients-section- bg-gradient15 pad-tb">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="common-heading">
              <span>{t("HomeContent.Clients.Title")}</span>
              <h2 className="mb30">{t("HomeContent.Clients.Description")}</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="clients-logos text-center col-12">
              <ul className="row text-center clearfix">
                <Marquee>
                  <li data-wow-delay=".2s">
                    <div className="brand-logo">
                      <img
                        src="https://res.cloudinary.com/deafomwc7/image/upload/v1664837514/codespace/images/client/rumahkinasih_naqsl1.png"
                        alt="clients"
                        className="img-fluid"
                      />
                    </div>
                    <p>Rumahkinasih Blitar</p>
                  </li>
                  <li data-wow-delay=".4s">
                    <div className="brand-logo">
                      <img
                        src="https://res.cloudinary.com/deafomwc7/image/upload/v1664837514/codespace/images/client/buangdisini_byuwai.png"
                        alt="clients"
                        className="img-fluid"
                      />
                    </div>
                    <p>PT Daur Ulang Bumi</p>
                  </li>
                  <li data-wow-delay=".6s">
                    <div className="brand-logo">
                      <img
                        src="https://res.cloudinary.com/deafomwc7/image/upload/v1664837513/codespace/images/client/kalamakara_bnxivg.png"
                        alt="clients"
                        className="img-fluid"
                      />
                    </div>
                    <p>Kalamakara Coffee & Roastery</p>
                  </li>
                  <li data-wow-delay=".8s">
                    <div className="brand-logo">
                      <img
                        src="https://res.cloudinary.com/deafomwc7/image/upload/v1664837514/codespace/images/client/k2pu_hgytfn.png"
                        alt="clients"
                        className="img-fluid"
                      />
                    </div>
                    <p>K2PU FIA Univ. Brawijaya</p>
                  </li>
                  <li data-wow-delay="1s">
                    <div className="brand-logo">
                      <img
                        src="https://res.cloudinary.com/deafomwc7/image/upload/v1664837514/codespace/images/client/japfa_hdinza.jpg"
                        alt="clients"
                        className="img-fluid"
                      />
                    </div>
                    <p>PT Ciomas Adisatwa</p>
                  </li>
                  <li data-wow-delay="1s">
                    <div className="brand-logo">
                      <img
                        src="https://res.cloudinary.com/deafomwc7/image/upload/v1710228410/codespace/images/client/Group_2_stmimz.png"
                        alt="clients"
                        className="img-fluid"
                      />
                    </div>
                    <p>PT Surabaya Industrial Estate Rungkut</p>
                  </li>
                  <li data-wow-delay="1s">
                    <div className="brand-logo">
                      <img
                        src="https://res.cloudinary.com/deafomwc7/image/upload/v1710228342/codespace/images/client/Group_3_amzujk.png"
                        alt="clients"
                        className="img-fluid"
                      />
                    </div>
                    <p>Bank Indonesia</p>
                  </li>
                  <li data-wow-delay="1s">
                    <div className="brand-logo">
                      <img
                        src="https://res.cloudinary.com/deafomwc7/image/upload/v1710228517/codespace/images/client/pgn_yil7s2.png"
                        alt="clients"
                        className="img-fluid"
                      />
                    </div>
                    <p>PT Pertamina Gas Negara Tbk</p>
                  </li>
                </Marquee>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
