import React from "react";
import { useTranslation } from "react-i18next";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function Solutions(props) {
  const { t, i18n } = useTranslation();

  return (
    <SkeletonTheme highlightColor="#002A66">
      <section className="case-study pad-tb bg-gradient5 deep-dark">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 block-1 v-center">
              <div className="common-heading inner-heading text-l pr25">
                <span>{t("Portofolio.Detail.Solutions")}</span>
                <h2>
                  {props?.portoDetail?.solution?.filter(
                    (x) => x.lang === i18n.language
                  )[0]?.title || <Skeleton />}
                </h2>

                <p>
                  {props?.portoDetail?.solution?.filter(
                    (x) => x.lang === i18n.language
                  )[0]?.description || <Skeleton height={300} />}{" "}
                </p>
              </div>
              <div className="card-nx row">
                {props?.portoDetail?.techStack?.map((obj) => (
                  <div className="col-lg-3 col-md-3 col-sm-6 col-6 mt30">
                    <div className="icon-card-">
                      <img
                        src="/assets/images/icons/trasparency.svg"
                        alt="case study"
                        className="img-fluid"
                      />
                      <h4>{obj}</h4>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </SkeletonTheme>
  );
}
