import React from "react";
import { useTranslation } from "react-i18next";

export default function LetsWork(_props) {
  const { t } = useTranslation();
  return (
    <section className="cta-area pad-tb">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="common-heading">
              <span>{t("HomeContent.LetsWork.Title")}</span>
              <h2>{t("HomeContent.LetsWork.Description")}</h2>
              {/* <a
                href="https://astroflaz.codespace.id"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-outline"
              >
                {t("HomeContent.LetsWork.Button")}{" "}
                <i className="fas fa-chevron-right fa-icon"></i>
              </a> */}
              <p className="cta-call">
                {t("HomeContent.LetsWork.Action")}{" "}
                <a
                  href="tel:+6282141866633"
                  data-umami-event="Send WA From Section Lets Work"
                >
                  <i className="fas fa-phone-alt"></i> (+62) 821-4186-6633
                  (Hiegar)
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="shape shape-a1">
        <img src="assets/images/shape/shape-3.svg" alt="shape" />
      </div>
      <div className="shape shape-a2">
        <img src="assets/images/shape/shape-4.svg" alt="shape" />
      </div>
      <div className="shape shape-a3">
        <img src="assets/images/shape/shape-13.svg" alt="shape" />
      </div>
      <div className="shape shape-a4">
        <img src="assets/images/shape/shape-11.svg" alt="shape" />
      </div>
    </section>
  );
}
