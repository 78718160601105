import React from "react";
import { useTranslation } from "react-i18next";

export default function About(props) {
  const { t } = useTranslation();
  return (
    <section className="about-agency pad-tb block-1">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 v-center">
            <div className="about-image">
              <img
                src="assets/images/about/codespace-about.png"
                alt="about us"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="common-heading text-l ">
              <span>{t("AboutUsContent.SectionOne.Title")}</span>
              <h2>Codespace Indonesia</h2>
              <p>{t("AboutUsContent.SectionOne.Description")} </p>
              <br />
              <h6>{t("AboutUsContent.SectionOne.Value.Title")}</h6>
              <p>
                <li>{t("AboutUsContent.SectionOne.Value.List.0")}</li>
                <li> {t("AboutUsContent.SectionOne.Value.List.1")}</li>
                <li> {t("AboutUsContent.SectionOne.Value.List.2")}</li>
                <li> {t("AboutUsContent.SectionOne.Value.List.3")}</li>
                <li>{t("AboutUsContent.SectionOne.Value.List.4")}</li>
              </p>
            </div>
            <div className="row in-stats small about-statistics">
              <div className="col-lg-4 col-sm-4">
                <div className="statistics">
                  <div className="statnumb counter-number">
                    <span className="counter">22</span>
                    <p> {t("AboutUsContent.SectionOne.Counter.Title.0")}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-4">
                <div className="statistics">
                  <div className="statnumb">
                    <span className="counter">4</span>
                    <p>{t("AboutUsContent.SectionOne.Counter.Title.1")}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-4">
                <div className="statistics mb0">
                  <div className="statnumb counter-number">
                    <span className="counter">22</span>
                    <p>{t("AboutUsContent.SectionOne.Counter.Title.2")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
