import React from "react";
import { useTranslation } from "react-i18next";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function AboutProject(props) {
  const { t, i18n } = useTranslation();
  return (
    <SkeletonTheme highlightColor="#002A66">
      <section className="case-study pad-tb">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="image-block upset">
                {props?.portoDetail?.imageMain ? (
                  <img
                    src={props?.portoDetail?.imageMain}
                    alt="case study"
                    className="img-fluid no-shadow"
                  />
                ) : (
                  <Skeleton height={300} />
                )}
              </div>
            </div>
            <div className="col-lg-6 block-1 v-center">
              <div className="common-heading inner-heading text-l pl25">
                <span>{t("Portofolio.Detail.AboutTheProject")}</span>
                <h2>{props?.portoDetail?.name || <Skeleton />}</h2>
                <p>
                  {props?.portoDetail?.description?.filter(
                    (x) => x.lang === i18n.language
                  )[0]?.content || <Skeleton height={150} />}
                </p>
              </div>
              {props?.portoDetail?.type === 1 ? (
                <div className="project-platform mt60 pl25">
                  <div className="project-platform-used -shadow">
                    <a href="#/">
                      <img
                        src="/assets/images/icons/chrome.svg"
                        alt="windows"
                      />
                    </a>
                  </div>
                </div>
              ) : props?.portoDetail?.type ? (
                <div className="project-platform mt60 pl25">
                  <div className="project-platform-used -shadow">
                    <a href="#/">
                      <img
                        src="/assets/images/icons/android.svg"
                        alt="android"
                      />
                    </a>
                  </div>
                  <div className="project-platform-used -shadow">
                    <a href="#/">
                      <img src="/assets/images/icons/apple.svg" alt="apple" />
                    </a>
                  </div>
                </div>
              ) : (
                <div className="project-platform mt60 pl25">
                  <Skeleton circle={true} height={80} width={80} />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </SkeletonTheme>
  );
}
