import React from "react";
import { useTranslation } from "react-i18next";

export default function WhyChoose(props) {
  const { t } = useTranslation();
  return (
    <section className="why-choos-lg pad-tb deep-dark">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="common-heading text-l">
              <span>{t("HomeContent.WhyChooseUs.Title")}</span>
              <h2 className="mb20">
                {t("HomeContent.WhyChooseUs.PreDescriptionOne")}{" "}
                <span className="text-second text-bold">
                  Codespace Indonesia
                </span>{" "}
                {t("HomeContent.WhyChooseUs.Description")}
              </h2>
              <div className="itm-media-object mt40 tilt-3d">
                <div className="media">
                  <div
                    className="img-ab- base"
                    data-tilt
                    data-tilt-max="20"
                    data-tilt-speed="1000"
                  >
                    <img
                      src="assets/images/icons/computers.svg"
                      alt="icon"
                      className="layer"
                    />
                  </div>
                  <div className="media-body">
                    <h4>{t("HomeContent.WhyChooseUs.Contains.0.Title")}</h4>
                    <p>{t("HomeContent.WhyChooseUs.Contains.0.Description")}</p>
                  </div>
                </div>
                <div className="media mt40">
                  <div
                    className="img-ab- base"
                    data-tilt
                    data-tilt-max="20"
                    data-tilt-speed="1000"
                  >
                    <img
                      src="assets/images/icons/worker.svg"
                      alt="icon"
                      className="layer"
                    />
                  </div>
                  <div className="media-body">
                    <h4>{t("HomeContent.WhyChooseUs.Contains.1.Title")}</h4>
                    <p>{t("HomeContent.WhyChooseUs.Contains.1.Description")}</p>
                  </div>
                </div>
                <div className="media mt40">
                  <div
                    className="img-ab- base"
                    data-tilt
                    data-tilt-max="20"
                    data-tilt-speed="1000"
                  >
                    {" "}
                    <img
                      src="assets/images/icons/deal.svg"
                      alt="icon"
                      className="layer"
                    />
                  </div>
                  <div className="media-body">
                    <h4>{t("HomeContent.WhyChooseUs.Contains.2.Title")}</h4>
                    <p>{t("HomeContent.WhyChooseUs.Contains.2.Description")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div
              data-tilt
              data-tilt-max="5"
              data-tilt-speed="1000"
              className="single-image bg-shape-dez wow fadeIn"
              data-wow-duration="2s"
            >
              <img
                src="https://res.cloudinary.com/deafomwc7/image/upload/v1710229364/codespace/images/WhyChooseUs/Screenshot_2024-03-12_at_14.41.16_zcei8q.png"
                alt="img-codespace-indonesia"
                className="img-fluid"
              />
            </div>
            <div className="cta-card mt60 text-center">
              <h3 className="mb20">
                {t("HomeContent.WhyChooseUs.RightSection.PreTitle")}{" "}
                <span className="text-second text-bold">
                  {" "}
                  {t("HomeContent.WhyChooseUs.RightSection.Title")}
                </span>{" "}
                {t("HomeContent.WhyChooseUs.RightSection.LastTitle")}
              </h3>
              <p>{t("HomeContent.WhyChooseUs.RightSection.Description")}</p>
              <a
                href="https://api.whatsapp.com/send?phone=6282141866633&text=%20Halo%20admin,%20saya%20minta%20proposal%20penawaran%20harga%20untuk%20pembuatan%20website/aplikasi..."
                className="btn-outline bg-btn4 lnk mt30"
                data-umami-event="Send WA From Section Why Choose Us"
              >
                {t("HomeContent.WhyChooseUs.RightSection.Button")}{" "}
                <i className="fas fa-chevron-right fa-icon"></i>
                <span className="circle"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
