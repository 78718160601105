import React from "react";
import { useTranslation } from "react-i18next";

export default function OurValue(props) {
  const { t } = useTranslation();
  return (
    <section className="why-choose pad-tb">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="common-heading">
              <span>{t("AboutUsContent.SectionTwo.Title")}</span>
              <h2 className="mb30">
                {t("AboutUsContent.SectionTwo.Description")}
              </h2>
            </div>
          </div>
        </div>
        <div className="row upset">
          <div className="col-lg-3 col-sm-6 mt30">
            <div className="s-block up-hor">
              <div className="s-card-icon">
                <img
                  src="assets/images/icons/research.svg"
                  alt="service"
                  className="img-fluid"
                />
              </div>
              <h4>{t("AboutUsContent.SectionTwo.List.0.Title")}</h4>
              <p>{t("AboutUsContent.SectionTwo.List.0.Description")}</p>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 mt30">
            <div className="s-block up-hor">
              <div className="s-card-icon">
                <img
                  src="assets/images/icons/chat.svg"
                  alt="service"
                  className="img-fluid"
                />
              </div>
              <h4>{t("AboutUsContent.SectionTwo.List.1.Title")}</h4>
              <p>{t("AboutUsContent.SectionTwo.List.1.Description")}</p>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 mt30">
            <div className="s-block up-hor">
              <div className="s-card-icon">
                <img
                  src="assets/images/icons/monitor.svg"
                  alt="service"
                  className="img-fluid"
                />
              </div>
              <h4>{t("AboutUsContent.SectionTwo.List.2.Title")}</h4>
              <p>{t("AboutUsContent.SectionTwo.List.2.Description")}</p>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 mt30">
            <div className="s-block up-hor">
              <div className="s-card-icon">
                <img
                  src="assets/images/icons/trasparency.svg"
                  alt="service"
                  className="img-fluid"
                />
              </div>
              <h4>{t("AboutUsContent.SectionTwo.List.3.Title")}</h4>
              <p>{t("AboutUsContent.SectionTwo.List.3.Description")}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
