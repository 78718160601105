import React from "react";
import { useTranslation } from "react-i18next";

export default function TeamMember(props) {
  const { t } = useTranslation();
  return (
    <section className="team pad-tb deep-dark">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="common-heading ptag">
              <span>{t("OurTeamContent.SectionTwo.Title")}</span>
              <h2>{t("OurTeamContent.SectionTwo.Description")}</h2>
              <p className="mb0"></p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-sm-6">
            <div className="full-image-card hover-scale">
              <div className="image-div">
                <a href="/#">
                  <img
                    src="https://res.cloudinary.com/deafomwc7/image/upload/v1727743377/codespace/images/team/farkhan-fen_u93i1s.webp"
                    alt="team"
                    className="img-fluid"
                    style={{ height: "320px" }}
                  />
                </a>
              </div>
              <div className="info-text-block">
                <h4>
                  <a href="/#">M Farkhan Syafii</a>
                </h4>
                <p>Fullstack Developer</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="full-image-card hover-scale">
              <div className="image-div">
                <a href="/#">
                  <img
                    src="assets/images/team/wahyu.png"
                    alt="team"
                    className="img-fluid"
                    style={{ height: "320px" }}
                  />
                </a>
              </div>
              <div className="info-text-block">
                <h4>
                  <a href="/#">Wahyu Ridiansyah</a>
                </h4>
                <p>PM, FE Engineer & Sys Analyst</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="full-image-card hover-scale">
              <div className="image-div">
                <a href="/#">
                  <img
                    src="assets/images/team/taufiq.png"
                    alt="team"
                    className="img-fluid"
                    style={{ height: "320px" }}
                  />
                </a>
              </div>
              <div className="info-text-block">
                <h4>
                  <a href="/#">Taufiq Aryadi</a>
                </h4>
                <p>Mobile Engineer</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="full-image-card hover-scale">
              <div className="image-div">
                <a href="/#">
                  <img
                    src="assets/images/team/irsyad.png"
                    alt="team"
                    className="img-fluid"
                    style={{ height: "320px" }}
                  />
                </a>
              </div>
              <div className="info-text-block">
                <h4>
                  <a href="/#">Irsyaad Budi</a>
                </h4>
                <p>Fullstack Developer</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="full-image-card hover-scale">
              <div className="image-div">
                <a href="/#">
                  <img
                    src="assets/images/team/adimasbe.png"
                    alt="team"
                    className="img-fluid"
                    style={{ height: "320px" }}
                  />
                </a>
              </div>
              <div className="info-text-block">
                <h4>
                  <a href="/#">Adimas Surya</a>
                </h4>
                <p>Backend Engineer</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="full-image-card hover-scale">
              <div className="image-div">
                <a href="/#">
                  <img
                    src="https://res.cloudinary.com/deafomwc7/image/upload/v1689315958/codespace/images/team/adit-cropped_pz95yy.png"
                    alt="team"
                    className="img-fluid"
                    style={{ height: "320px" }}
                  />
                </a>
              </div>
              <div className="info-text-block">
                <h4>
                  <a href="/#">Aditya CP</a>
                </h4>
                <p>Infra/DevOps</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="full-image-card hover-scale">
              <div className="image-div">
                <a href="/#">
                  <img
                    src="https://res.cloudinary.com/deafomwc7/image/upload/v1664837476/codespace/images/team/aldo_ltkbtl.png"
                    alt="team"
                    className="img-fluid"
                    style={{ height: "320px" }}
                  />
                </a>
              </div>
              <div className="info-text-block">
                <h4>
                  <a href="/#">Aldo Ignata C</a>
                </h4>
                <p>Backend Engineer</p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
          
        </div> */}
      </div>
    </section>
  );
}
