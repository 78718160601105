/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

export default function Careers(props) {
  return (
    <section className="team bg-gradient99">
      <div class="container">
        <div class="row upset ovr-bg2 bd-hor">
          <div class="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".2s">
            <div
              class="s-block up-hor bd-hor-base"
              style={{ textAlign: "left" }}
            >
              <div class="nn-card-set">
                <h3 style={{ color: "#FFC007" }}>Mobile Developer (Flutter)</h3>
                <br />
                <h6>Job: Mobile Developer</h6>
                <h6>Grade: Senior</h6>
                <h6>Experience: 3 Years+</h6>
                <h6>Employee Type: Fulltime, Project Based</h6>
                <br />

                <h4>Description</h4>
                <p>
                  We are hiring a senior Flutter developer to engineer
                  applications that will run across Android and iOS. We are
                  looking for someone passionate about performance, reliability,
                  and design with the experience and credentials to back up that
                  passion.
                </p>
                <br />

                <h4>Requirements</h4>
                <ul class="list-style-">
                  <li>Mahir menggunakan Dart(Flutter)</li>
                  <li>
                    Minimal 3 tahun pengalaman menggunakan Flutter dan Dart.
                  </li>
                  <li>
                    Mampu menulis kode yang bersih, mudah dipelihara, dan
                    scalable
                  </li>
                  <li>Good problem-solving skills</li>
                  <li>Excellent verbal communication skills.</li>
                  <li>Good interpersonal skills.</li>
                </ul>
                <br />

                <h4>Responsibility</h4>
                <ul class="list-style-">
                  <li>
                    Design, develop, and maintain mobile applications for both
                    iOS and Android platforms using Flutter.
                  </li>
                  <li>
                    Implement core features such as maps real-time tracking.
                  </li>
                  <li>
                    Integrate and manage third-party payment gateways to ensure
                    secure and seamless transactions
                  </li>
                  <li>
                    Implement Google Maps API to enable restaurant discovery
                    based on location and real-time driver tracking for orders.
                  </li>
                  <li>
                    Ensure high performance, responsiveness, and scalability of
                    the app for various devices and screen sizes.
                  </li>
                  <li>
                    Work closely with the backend team to integrate APIs,
                    ensuring smooth data integrations.
                  </li>
                  <li>
                    Troubleshoot issues, fix bugs, and ensure the app is
                    functioning as expected.
                  </li>
                </ul>
                <br />

                <h4>Benefits</h4>
                <ul class="list-style-">
                  <li>
                    WFA (Work From Anywhere) - Kerja bebas dari mana aja, lebih
                    fleksibel!
                  </li>
                </ul>

                {/* <div style={{ textAlign: "right" }}>
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSeMdVM2_geLAE0HEPMKVUpcBHyiLWh164b0b7V4Njv9z2PO7Q/viewform"
                    rel="noopener noreferrer"
                    style={{ color: "#FFC007", textAlign: "right" }}
                    target="_blank"
                  >
                    Apply <i class="fas fa-chevron-right fa-icon"></i>
                  </a>
                </div> */}
                <div style={{ textAlign: "right" }}>
                  <a
                    href="javascript:void(0)"
                    rel="noopener noreferrer"
                    style={{ color: "grey", textAlign: "right" }}
                  >
                    Closed <i class="fas fa-chevron-right fa-icon"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".2s">
            <div
              class="s-block up-hor bd-hor-base"
              style={{ textAlign: "left" }}
            >
              <div class="nn-card-set">
                <h3 style={{ color: "#FFC007" }}>Fullstack Engineer</h3>
                <br />
                <h6>Job: Fullstack Engineer</h6>
                <h6>Grade: Middle to Senior</h6>
                <h6>Experience: 3 Years+</h6>
                <h6>Employee Type: Fulltime with Contract 1 Year</h6>
                <br />

                <h4>Description</h4>
                <p>
                  We are hiring a middle to senior Fullstack developer to join
                  our Codespace Dev Team. As a fullstack you are passionate
                  about performance, reliability, and design. You are growth
                  mindset with passionate of learning, driven by curiosity.
                </p>
                <br />

                <h4>Requirements</h4>
                <ul class="list-style-">
                  <li>Mahir menggunakan Dart(Flutter)</li>
                  <li>Mahir menggunakan PHP (Laravel & WordPress)</li>
                  <li>
                    Pengalaman menggunakan Laravel Filamen lebih disukai/nilai
                    tambah
                  </li>
                  <li>Pernah menggunakan TypeScript (NestJS)</li>
                  <li>Familiar menggunakan ReactJS</li>
                  <li>Pernah menggunakan InertiaJs adalah nilai plus</li>
                  <li>Familiar menggunakan MongoDB, MySQL, dan Redis</li>
                  <li>
                    Familiar dengan tools CI/CD serta Pengalaman menulis Unit
                    Test adalah nilai plus
                  </li>
                </ul>
                <br />

                <h4>Responsibility</h4>
                <ul class="list-style-">
                  <li>
                    Design, develop, and maintain all of Application that
                    Codespace Build and Have.
                  </li>
                  <li>
                    Ensure high performance, responsiveness, and scalability of
                    the app for various devices and screen sizes.
                  </li>
                  <li>
                    Troubleshoot issues, fix bugs, and ensure the app is
                    functioning as expected.
                  </li>
                </ul>
                <br />

                <h4>Benefits</h4>
                <ul class="list-style-">
                  <li>
                    WFA (Work From Anywhere) - Kerja bebas dari mana aja, lebih
                    fleksibel!
                  </li>
                  <li>Tunjangan Asuransi BPJS Kelas 1</li>
                  <li>THR</li>
                  <li>Cuti Tahunan</li>
                  <li>Bonus Kuartal (Jika revenue company sedang bagus)</li>
                </ul>

                <div style={{ textAlign: "right" }}>
                  <a
                    href="javascript:void(0)"
                    rel="noopener noreferrer"
                    style={{ color: "grey", textAlign: "right" }}
                  >
                    Closed <i class="fas fa-chevron-right fa-icon"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
