import React from "react";
import { useTranslation } from "react-i18next";

export default function ContactForm(props) {
  const { t } = useTranslation();
  return (
    <section className="contact-page pad-tb">
      <div className="container">
        <div className="row justify-content-left">
          <div className="col-lg-6 v-center">
            <div className="contact-details">
              <div className="contact-card wow fadeIn" data-wow-delay=".2s">
                <div className="info-card v-center">
                  <span>
                    <i className="fas fa-phone-alt"></i>{" "}
                    {t("ContactUsContent.List.0.Title")} :
                  </span>
                  <div className="info-body">
                    <p>{t("ContactUsContent.List.0.Description")}</p>
                    <a
                      href="tel:+6282141866633"
                      className="btn-main bg-btn lnk wow fadeIn"
                      data-wow-delay="0.8s"
                      style={{ color: "#FAFAFA", cursor: "pointer" }}
                      data-umami-event="Send WA From Menu Contact Us"
                    >
                      {t("ContactUsContent.List.0.Button")}{" "}
                      <i className="fas fa-chevron-right fa-ani"></i>
                      <span className="circle"></span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="email-card mt30 wow fadeIn" data-wow-delay=".5s">
                <div className="info-card v-center">
                  <span>
                    <i className="fas fa-envelope"></i>{" "}
                    {t("ContactUsContent.List.1.Title")} :
                  </span>
                  <div className="info-body">
                    <p>{t("ContactUsContent.List.1.Description")}</p>
                    <a
                      href="mailto:mail@codespace.id"
                      className="btn-main bg-btn lnk wow fadeIn"
                      data-wow-delay="0.8s"
                      style={{ color: "#FAFAFA", cursor: "pointer" }}
                      data-umami-event="Send Email From Menu Contact Us"
                    >
                      {t("ContactUsContent.List.1.Button")}{" "}
                      <i className="fas fa-chevron-right fa-ani"></i>
                      <span className="circle"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
