import React from "react";
import { useTranslation } from "react-i18next";

export default function OfficeLocation(props) {
  const { t } = useTranslation();
  return (
    <section className="contact-location pad-tb bglight">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="common-heading">
              <span>{t("HomeContent.Location.Title")}</span>
              <h2>{t("HomeContent.Location.Description")}</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center upset shape-numm">
          <div
            className="col-lg-4 col-sm-6 shape-loc wow fadeInUp"
            data-wow-delay=".2s"
          >
            <div className="office-card">
              <div className="skyline-img">
                <img
                  src="https://res.cloudinary.com/deafomwc7/image/upload/v1733369786/codespace/images/location/next-space_rdxc0g.webp"
                  alt="malang"
                  className="img-fluid"
                />
              </div>
              <div className="office-text">
                <h4>Next SPACE (Osborn)</h4>
                <p>
                  Jl. Arumba No.7, RT.001/RW.004 Tunggulwulung, Kec. Lowokwaru,
                  Kota Malang, Jawa Timur 65143
                </p>
                <a
                  href="https://www.google.com/maps/dir/-6.2824191,107.0185126/Jl.+Angklung+No.H1,+Tunggulwulung,+Kec.+Lowokwaru,+Kota+Malang,+Jawa+Timur+65143/@-7.0357963,105.3188128,6z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x2e788206ffc7ab33:0x7ef65f413b0a7f00!2m2!1d112.6168037!2d-7.9291178"
                  target="blank"
                  className="btn-outline"
                >
                  View on Map <i className="fas fa-chevron-right fa-icon"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
