import React from "react";
import { useTranslation } from "react-i18next";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function Feature(props) {
  const { t, i18n } = useTranslation();

  return (
    <SkeletonTheme highlightColor="#002A66">
      <section className="case-study pad-tb bg-gradient6 deep-dark">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="common-heading">
                <span>{t("Portofolio.Detail.AppFeature")}</span>
                <h2 className="mb30">
                  {t("Portofolio.Detail.ApplicationFeature")}
                </h2>
              </div>
            </div>
          </div>
          {props?.portoDetail?.feature ? (
            <div className="row">
              {props?.portoDetail?.feature?.map((obj, i) => (
                <div className="col-lg-6" key={i}>
                  <h4 className="mt20 mb10">
                    {
                      obj?.title?.filter((x) => x.lang === i18n.language)[0]
                        ?.content
                    }
                  </h4>
                  <ul className="list-style-">
                    {obj?.featureList
                      ?.filter((x) => x.lang === i18n.language)[0]
                      ?.contents?.map((obj, i) => (
                        <li key={i}>{obj}</li>
                      ))}
                  </ul>
                </div>
              ))}
            </div>
          ) : (
            <Skeleton height={400} />
          )}
        </div>
      </section>
    </SkeletonTheme>
  );
}
