import React, { useEffect } from "react";
import MainBanner from "./component/MainBanner";
import ShortAbout from "./component/ShortAbout";
import Service from "./component/Service";
import WhyChoose from "./component/WhyChoose";
import Portfolio from "./component/Portfolio";
import Clients from "./component/Clients";
import LetsWork from "./component/LetsWork";
import Location from "./component/Location";

export default function Index(props) {
  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "assets/js/main.js";

    document.body.appendChild(script);
  }, []);
  return (
    <div>
      <MainBanner />
      <ShortAbout />
      <Service />
      <WhyChoose />
      <Portfolio />
      <Clients />
      <LetsWork />
      <Location />
    </div>
  );
}
