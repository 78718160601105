import React from "react";
import { useTranslation } from "react-i18next";

export default function Location(props) {
  const { t } = useTranslation();
  return (
    <section className="our-office pad-tb">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="common-heading">
              <span>{t("HomeContent.Location.Title")}</span>
              <h2>{t("HomeContent.Location.Description")}</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center upset shape-numm">
          <div
            className="col-lg-4 col-sm-6 shape-loc wow fadeInUp"
            data-wow-delay=".2s"
          >
            <div className="office-card">
              <div
                className="skyline-img"
                data-tilt
                data-tilt-max="4"
                data-tilt-speed="1000"
              >
                <img
                  src="https://res.cloudinary.com/deafomwc7/image/upload/v1733369786/codespace/images/location/next-space_rdxc0g.webp"
                  alt="malang"
                  className="img-fluid"
                />
              </div>
              <div className="office-text">
                <h4>Next SPACE (Osborn)</h4>
                <p>
                  Jl. Arumba No.7, RT.001/RW.004 Tunggulwulung, Kec. Lowokwaru,
                  Kota Malang, Jawa Timur 65143
                </p>
                <a
                  href="https://maps.app.goo.gl/9ojxoRSLD6PrPs6x8"
                  target="blank"
                  className="btn-outline"
                >
                  View on Map <i className="fas fa-chevron-right fa-icon"></i>
                </a>
              </div>
            </div>
          </div>
          {/* <div
            className="col-lg-4 col-sm-6 shape-loc wow fadeInUp"
            data-wow-delay=".5s"
          ></div> */}
        </div>
      </div>
    </section>
  );
}
