import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getListPorto } from "../../../services/PortoServices";

export default function Portfolio(props) {
  const { t } = useTranslation();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getListPorto();
        setData(result);
      } catch (error) {
        // Handle error (e.g., set error state)
      }
    };

    fetchData();
  }, []);

  return (
    <section className="portfolio-section pad-tb" id="our-latest-works">
      <div className="container">
        <div className="row justify-content-center ">
          <div className="col-lg-8">
            <div className="common-heading">
              <span>{t("HomeContent.OurWork.Title")}</span>
              <h2 className="mb0">{t("HomeContent.OurWork.Description")}</h2>
            </div>
          </div>
        </div>
        <div className="row">
          {data?.map((obj) => (
            <div
              className="col-lg-6 col-sm-6 mt60 wow fadeInUp"
              data-wow-delay="0.2s"
              key={obj?.id}
              style={{
                marginRight: "auto",
                marginTop: "3rem",
              }}
            >
              <div className="isotope_item hover-scale">
                <div
                  className="item-image"
                  data-tilt
                  data-tilt-max="2"
                  data-tilt-speed="1000"
                >
                  <Link to={`/portofolio/` + obj?.id + ``}>
                    <img
                      src={obj?.imageMain}
                      alt="img-portofolio-1"
                      className="img-fluid"
                    />{" "}
                  </Link>
                </div>
                <div className="item-info">
                  <h4>
                    <Link to={`/portofolio/` + obj?.id + ``}>{obj?.name}</Link>
                  </h4>
                  <p>{obj?.type === 1 ? "Website Apps" : "Mobile Apps"}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
