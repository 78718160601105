import React from "react";
import { useTranslation } from "react-i18next";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function Challenges(props) {
  const { t, i18n } = useTranslation();

  return (
    <SkeletonTheme highlightColor="#002A66">
      <section className="case-study pad-tb">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-6">
              <h2 className="mt20 mb20">{t("Portofolio.Detail.Challenges")}</h2>
              <p>
                {props?.portoDetail?.challenges?.filter(
                  (x) => x.lang === i18n.language
                )[0]?.description || <Skeleton height={150} />}
              </p>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="project-view m-mt30">
                {props?.portoDetail?.challenges?.image ? (
                  <img
                    src={props?.portoDetail?.challenges?.image}
                    alt="challenges"
                    className="img-fluid no-shadow"
                  />
                ) : (
                  <img
                    src="/assets/images/codespace/banner2.svg"
                    alt="challenges"
                    className="img-fluid no-shadow"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </SkeletonTheme>
  );
}
