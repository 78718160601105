import React from "react";
import { useTranslation } from "react-i18next";

export default function Service(props) {
  const { t } = useTranslation();
  return (
    <section className="service-section-prb pad-tb">
      <div className="container">
        <div className="row upset">
          <div
            data-tilt
            data-tilt-max="5"
            data-tilt-speed="1000"
            className="col-lg-6-cus wow fadeInUp"
            data-wow-delay=".2s"
          >
            <div className="service-sec-brp srvc-bg-nx bg-gradient13 text-w">
              <h4 className="mb10">
                {t("HomeContent.Services.ITConsultant.Title")}
              </h4>
              <p>{t("HomeContent.Services.ITConsultant.Description")}</p>
              <a
                href="https://api.whatsapp.com/send?phone=6282141866633&text=%20Halo%20admin,%20saya%20ingin%20membuat%20website/aplikasi..."
                className="mt20 link-prb"
              >
                {t("HomeContent.Services.ITConsultant.Button")}{" "}
                <i className="fas fa-chevron-right"></i>
              </a>
            </div>
          </div>
          <div
            data-tilt
            data-tilt-max="5"
            data-tilt-speed="1000"
            className="col-lg-3-cus wow fadeInUp"
            data-wow-delay=".4s"
          >
            <div className="service-sec-list srvc-bg-nx srcl1">
              <img src="assets/images/icons/development.svg" alt="service" />
              <h5 className="mb10">
                {t("HomeContent.Services.WebApplication.Title")}
              </h5>
              <ul className="-service-list">
                <li>
                  {" "}
                  <a href="/#">React.JS</a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="/#">Node.JS</a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="/#">Laravel</a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="/#">Golang</a>
                </li>
              </ul>
              <p>{t("HomeContent.Services.WebApplication.Description")}</p>
            </div>
          </div>

          <div
            data-tilt
            data-tilt-max="5"
            data-tilt-speed="1000"
            className="col-lg-3-cus wow fadeInUp"
            data-wow-delay=".6s"
          >
            <div className="service-sec-list srvc-bg-nx srcl3">
              <img src="assets/images/icons/app.svg" alt="service" />
              <h5 className="mb10">
                {t("HomeContent.Services.MobileApplication.Title")}
              </h5>
              <ul className="-service-list">
                <li>
                  {" "}
                  <a href="/#">iOS </a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="/#">Android</a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="/#">Hybrid </a>
                </li>
              </ul>
              <p>{t("HomeContent.Services.MobileApplication.Description")}</p>
            </div>
          </div>
          <div
            data-tilt
            data-tilt-max="5"
            data-tilt-speed="1000"
            className="col-lg-3-cus mt30- wow fadeInUp"
            data-wow-delay=".8s"
          >
            <div className="service-sec-list srvc-bg-nx srcl2">
              <img src="assets/images/icons/ecommerce.svg" alt="service" />
              <h5 className="mb10">
                {t("HomeContent.Services.CompanyProfile.Title")}
              </h5>
              <ul className="-service-list">
                <li>
                  {" "}
                  <a href="/#">Wordpress</a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="/#">NextJs</a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="/#">SSR/SSG</a>
                </li>
              </ul>
              <p>{t("HomeContent.Services.CompanyProfile.Description")}</p>
            </div>
          </div>
          <div
            data-tilt
            data-tilt-max="5"
            data-tilt-speed="1000"
            className="col-lg-3-cus mt30- wow fadeInUp"
            data-wow-delay="1s"
          >
            <div className="service-sec-list srvc-bg-nx srcl4">
              <img src="assets/images/icons/tech.svg" alt="service" />
              <h5 className="mb10">
                {t("HomeContent.Services.Maintenance.Title")}
              </h5>
              <ul className="-service-list">
                <li>
                  {" "}
                  <a href="/#">
                    {t("HomeContent.Services.Maintenance.OptionOne")}{" "}
                  </a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="/#">
                    {t("HomeContent.Services.Maintenance.OptionTwo")}{" "}
                  </a>{" "}
                </li>
              </ul>
              <p>{t("HomeContent.Services.Maintenance.Description")}</p>
            </div>
          </div>

          <div
            data-tilt
            data-tilt-max="5"
            data-tilt-speed="1000"
            className="col-lg-6-cus mt30- wow fadeInUp"
            data-wow-delay="1.2s"
          >
            <div className="service-sec-list srvc-bg-nx srcl5">
              <img src="assets/images/icons/seo.svg" alt="service" />
              <h5 className="mb10">
                {t("HomeContent.Services.DigitalMarketing.Title")}
              </h5>
              <ul className="-service-list">
                <li>
                  {" "}
                  <a href="/#">
                    {t("HomeContent.Services.DigitalMarketing.OptionOne")}{" "}
                  </a>{" "}
                </li>
              </ul>
              <p>{t("HomeContent.Services.DigitalMarketing.Description")}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
