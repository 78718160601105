import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function MainBanner(props) {
  const { t } = useTranslation();
  const [serviceList, setServiceList] = useState([
    {
      color_id: "a",
      img: "assets/images/service/codespace/web-compro.png",
      name: t("HomeContent.Services.CompanyProfile.Title"),
    },
    {
      color_id: "b",
      img: "assets/images/service/codespace/web-apps.png",
      name: t("HomeContent.Services.WebApplication.Title"),
    },
    {
      color_id: "c",
      img: "assets/images/service/codespace/mobile-apps.png",
      name: t("HomeContent.Services.MobileApplication.Title"),
    },
  ]);

  useEffect(() => {
    setServiceList([
      {
        color_id: "a",
        img: "assets/images/service/codespace/web-compro.png",
        name: t("HomeContent.Services.CompanyProfile.Title"),
      },
      {
        color_id: "b",
        img: "assets/images/service/codespace/web-apps.png",
        name: t("HomeContent.Services.WebApplication.Title"),
      },
      {
        color_id: "c",
        img: "assets/images/service/codespace/mobile-apps.png",
        name: t("HomeContent.Services.MobileApplication.Title"),
      },
    ]);
  }, [t]);

  return (
    <section className="hero-card-web bg-gradient12 shape-bg3">
      <div className="hero-main-rp container-fluid">
        <div className="row">
          <div className="col-lg-5">
            <div className="hero-heading-sec">
              <h2 className="wow fadeIn">
                {" "}
                <span>{t("HomeContent.Affordable")}.</span>{" "}
                <span>{t("HomeContent.LatestTech")}.</span>{" "}
                <span>{t("HomeContent.BestService")}.</span>{" "}
                <span>{t("HomeContent.UIUXOptmization")}.</span>
              </h2>
              <p className="wow fadeIn" data-wow-delay="0.6s">
                {t("HomeContent.ShortVision")} {t("HomeContent.HashtagVision")}
                <div>
                  <a
                    className="navbar-brand mt10 mb10"
                    href="https://apps.apple.com/id/app/codespace-x/id6737539771"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/9/91/Download_on_the_App_Store_RGB_blk.svg"
                      alt="appstore"
                      width="120"
                    />
                  </a>

                  <a
                    className="navbar-brand mt10 mb10"
                    href="https://play.google.com/store/apps/dev?id=8808853319463086956"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                      alt="playstore"
                      width="120"
                    />
                  </a>
                </div>
              </p>

              <a
                href="/#our-latest-works"
                className="btn-main bg-btn lnk wow fadeIn"
                data-wow-delay="0.8s"
                style={{ color: "#FAFAFA", cursor: "pointer" }}
              >
                {t("HomeContent.LatestWorks")}{" "}
                <i className="fas fa-chevron-right fa-ani"></i>
                <span className="circle"></span>
              </a>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="hero-right-scmm">
              <div
                className="hero-service-cards wow fadeInRight"
                data-wow-duration="2s"
              >
                <div className="owl-carousel service-card-prb">
                  {serviceList.map((obj) => (
                    <div
                      className={"service-slide card-bg-" + obj.color_id}
                      data-tilt
                      data-tilt-max="5"
                      data-tilt-speed="1000"
                    >
                      <a href="/#">
                        <div className="service-card-hh">
                          <div className="image-sr-mm">
                            <img
                              alt="custom-sport"
                              src={obj.img}
                              style={{ maxWidth: "70%", marginTop: "15%" }}
                            />
                          </div>
                          <div
                            className="title-serv-c"
                            style={{ marginTop: "15%" }}
                          >
                            <span>{obj.name}</span>
                          </div>
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
